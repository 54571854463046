<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Tasks">
                    <template v-slot:description>
                        <div>import { HbTask } from 'hummingbird-aviary';</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card class="mt-4 mb-6">
            <template v-slot:title>
                HbTask Sandbox + Code <span class="ml-2"><hb-btn small @click="modalView = true">Open Modal View Variant</hb-btn></span>
            </template>

            <div class="ma-4">
                
                <v-row no-gutters>

                    <hb-modal
                        v-if="modalView"
                        title="Modal Window Title"
                        v-model="modalView"
                    >
                        <template v-slot:content>
                            <hb-task
                                modal-view
                                :type="type"
                                :id="id"
                                :status="status"
                                :hot-task="hotTask"
                                :hot-task-tooltip-header="hotTaskTooltipHeader"
                                :hot-task-tooltip-body="hotTaskTooltipBody"
                                :icon="icon"
                                :title="title"
                                :expanded.sync="expanded"
                                :time="time"
                                :timer-green-value="timerGreenValue"
                                :timer-red-value="timerRedValue"
                                :assigned-to-id="assignedToId"
                                :assigned-to="assignedTo"
                                :created-by-id="createdById"
                                :created-by="createdBy"
                                :created-by-time="createdByTime"
                                :completed-by-id="completedById"
                                :completed-by="completedBy"
                                :completed-by-time="completedByTime"
                                :updated-by-id="updatedById"
                                :updated-by="updatedBy"
                                :updated-by-time="updatedByTime"
                                :attachments="attachments"
                                :button-one="buttonOne"
                                :button-one-loading="buttonOneLoading"
                                :button-one-disabled="buttonOneDisabled"
                                :button-two="buttonTwo"
                                :button-two-loading="buttonTwoLoading"
                                :button-two-disabled="buttonTwoDisabled"
                                :button-three="buttonThree"
                                :button-three-loading="buttonThreeLoading"
                                :button-three-disabled="buttonThreeDisabled"
                                :space-id="spaceId"
                                :space="space"
                                :space-type="spaceType"
                                :contact-id="contactId"
                                :contact-is-account="contactIsAccount"
                                :contact-is-business="contactIsBusiness"
                                :contact-name="contactName"
                                :contact-status="contactStatus"
                                :property-id="propertyId"
                                :property="property"
                                :footer-text="footerText"
                                @button-one-click="handleButtonOneClick"
                                @button-two-click="handleButtonTwoClick"
                                @button-three-click="handleButtonThreeClick"
                            >
                                <div class="mb-4">{{ message }}</div>

                                <hb-media-viewer
                                    v-model="mediaViewer"
                                    grid
                                    grid-padding-off
                                >
                                    <template v-slot:slides>
                                        <slide
                                            v-for="(slide, i) in slides"
                                            :key="'slide_' + i"
                                            :id="slide.id"
                                            :video="slide.video"
                                            :video_src="slide.video_src"
                                            :src="slide.src"
                                            :thumbnail="slide.thumbnail"
                                        />
                                    </template>
                                </hb-media-viewer>

                                <div class="hb-font-body-medium hb-text-night mt-n3">Attachments</div>

                                <div class="mt-3">
                                    <hb-chip prepend-icon="mdi-paperclip" pointer @click="doWhatever">documents1.pdf</hb-chip>
                                    <hb-chip prepend-icon="mdi-paperclip" pointer @click="doWhatever">documents2.pdf</hb-chip>
                                </div>
                            </hb-task>
                        </template>
                    </hb-modal>

                    <hb-task
                        :type="type"
                        :id="id"
                        :status="status"
                        :hot-task="hotTask"
                        :hot-task-tooltip-header="hotTaskTooltipHeader"
                        :hot-task-tooltip-body="hotTaskTooltipBody"
                        :icon="icon"
                        :title="title"
                        :expanded.sync="expanded"
                        :time="time"
                        :timer-green-value="timerGreenValue"
                        :timer-red-value="timerRedValue"
                        :assigned-to-id="assignedToId"
                        :assigned-to="assignedTo"
                        :created-by-id="createdById"
                        :created-by="createdBy"
                        :created-by-time="createdByTime"
                        :completed-by-id="completedById"
                        :completed-by="completedBy"
                        :completed-by-time="completedByTime"
                        :updated-by-id="updatedById"
                        :updated-by="updatedBy"
                        :updated-by-time="updatedByTime"
                        :attachments="attachments"
                        :button-one="buttonOne"
                        :button-one-loading="buttonOneLoading"
                        :button-one-disabled="buttonOneDisabled"
                        :button-two="buttonTwo"
                        :button-two-loading="buttonTwoLoading"
                        :button-two-disabled="buttonTwoDisabled"
                        :button-three="buttonThree"
                        :button-three-loading="buttonThreeLoading"
                        :button-three-disabled="buttonThreeDisabled"
                        :space-id="spaceId"
                        :space="space"
                        :space-type="spaceType"
                        :contact-id="contactId"
                        :contact-is-account="contactIsAccount"
                        :contact-is-business="contactIsBusiness"
                        :contact-name="contactName"
                        :contact-status="contactStatus"
                        :property-id="propertyId"
                        :property="property"
                        :footer-text="footerText"
                        @click="handleClick"
                        @button-one-click="handleButtonOneClick"
                        @button-two-click="handleButtonTwoClick"
                        @button-three-click="handleButtonThreeClick"
                    >
                        {{ message }}
                    </hb-task>

                </v-row>

                <v-row no gutters>

                    <v-col cols="4" no-gutters>
                        <hb-card class="mt-2" no-title width="552">

                            <hb-form label="type">
                                <HbSelect
                                    v-model="type"
                                    :items="typeItems"
                                    placeholder="Select Type"
                                />
                            </hb-form>

                            <hb-form label="id">
                                <template v-slot:tooltipBody>Optional</template>
                                <HbTextField
                                    v-model="id"
                                    placeholder="Enter ID"
                                />
                            </hb-form>

                            <hb-form label="hot-task">
                                <template v-slot:tooltipBody>
                                    If "status" is "complete" or "dismissed", then "hot-task" functionality is disabled.
                                </template>
                                <HbSwitch
                                    v-model="hotTask"
                                    :label="hotTask ? 'true' : 'false/null'"
                                    :disabled="status === 'complete' || status === 'dismissed'"
                                />
                            </hb-form>

                            <hb-form v-if="hotTask" label="hot-task-tooltip-header">
                                <HbTextField
                                    v-model="hotTaskTooltipHeader"
                                    placeholder="Enter Hot Task Tooltip Header"
                                />
                            </hb-form>

                            <hb-form v-if="hotTask" label="hot-task-tooltip-body">
                                <HbTextField
                                    v-model="hotTaskTooltipBody"
                                    placeholder="Enter Hot Task Tooltip Body"
                                />
                            </hb-form>

                            <hb-form label="icon">
                                <HbTextField
                                    v-model="icon"
                                    placeholder="Enter Icon"
                                />
                            </hb-form>

                            <hb-form label="title">
                                <HbTextField
                                    v-model="title"
                                    placeholder="Enter Title"
                                />
                            </hb-form>

                            <hb-form label="expanded">
                                <HbSwitch
                                    v-model="expanded"
                                    :label="expanded ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="type === 'contact' || type === 'space'" label="space-id">
                                <template v-slot:tooltipBody>Optional</template>
                                <HbTextField
                                    v-model="spaceId"
                                    placeholder="Enter Space ID"
                                />
                            </hb-form>

                            <hb-form v-if="type === 'contact' || type === 'space'" label="space">
                                <HbTextField
                                    v-model="space"
                                    placeholder="Enter Space"
                                />
                            </hb-form>

                            <hb-form v-if="type === 'contact' || type === 'space'" label="space-type">
                                <HbSelect
                                    v-model="spaceType"
                                    :items="spaceTypeItems"
                                    item-text="type"
                                    item-value="type"
                                    placeholder="Enter Space Type"
                                />
                            </hb-form>

                            <hb-form v-if="type === 'contact'" label="contact-id">
                                <template v-slot:tooltipBody>Optional</template>
                                <HbTextField
                                    v-model="contactId"
                                    placeholder="Enter Contact ID"
                                />
                            </hb-form>

                            <hb-form v-if="type === 'contact'" label="contact-is-account">
                                <HbSwitch
                                    v-model="contactIsAccount"
                                    :label="contactIsAccount ? 'true' : 'false/null'"
                                    :disabled="contactIsBusiness"
                                />
                            </hb-form>

                            <hb-form v-if="type === 'contact'" label="contact-is-business">
                                <HbSwitch
                                    v-model="contactIsBusiness"
                                    :label="contactIsBusiness ? 'true' : 'false/null'"
                                    :disabled="contactIsAccount"
                                />
                            </hb-form>

                            <hb-form v-if="type === 'contact'" label="contact-name">
                                <HbTextField
                                    v-model="contactName"
                                    placeholder="Enter Contact Name"
                                />
                            </hb-form>

                            <hb-form v-if="type === 'contact'" label="contact-status">
                                <HbTextField
                                    v-model="contactStatus"
                                    placeholder="Enter Contact Status"
                                />
                            </hb-form>

                            <hb-form label="propertyId">
                                <template v-slot:tooltipBody>Optional</template>
                                <HbTextField
                                    v-model="propertyId"
                                    placeholder="Enter Property Id"
                                />
                            </hb-form>

                            <hb-form label="property">
                                <HbTextField
                                    v-model="property"
                                    placeholder="Enter Property"
                                />
                            </hb-form>

                            <hb-form label="Default <slot>">
                                <HbTextarea
                                    v-model="message"
                                    placeholder="Enter Message"
                                />
                            </hb-form>

                            <hb-form v-if="type !== 'contact' && type !== 'space'" label="footer-text">
                                <HbTextField
                                    v-model="footerText"
                                    placeholder="Enter Footer Text"
                                />
                            </hb-form>

                        </hb-card>
                    
                    </v-col>

                    <v-col cols="4" no-gutters>

                        <hb-card class="mt-2" no-title width="552">

                            <hb-form label="status">
                                <HbSelect
                                    v-model="status"
                                    :items="statusItems"
                                    placeholder="Enter Status"
                                />
                            </hb-form>

                            <hb-form label="time">
                                <template v-slot:tooltipBody>
                                    Time only shows if "status" is "due date" or "overdue" or "undefined" (or not set at all)
                                </template>
                                <hb-radio-group v-model="setTime" :disabled="status && status !== 'due date' && status !== 'undefined' && status !== 'overdue'">
                    
                                    <HbRadio
                                        label="Today"
                                        value="today"
                                    />

                                    <HbRadio
                                        label="Yesterday"
                                        value="yesterday"
                                    />

                                    <HbRadio
                                        label="Two Days Ago"
                                        value="two days ago"
                                    />

                                </hb-radio-group>
                            </hb-form>

                            <hb-form label="timer-green-value">
                                <HbTextField
                                    v-model="timerGreenValue"
                                    :disabled="status !== 'timer green'"
                                    placeholder="Enter Countdown Value"
                                />
                            </hb-form>

                            <hb-form label="timer-red-value">
                                <HbTextField
                                    v-model="timerRedValue"
                                    :disabled="status !== 'timer red'"
                                    placeholder="Enter Timer Value"
                                />
                            </hb-form>

                            <hb-form label="assigned-to-id">
                                <template v-slot:tooltipBody>Optional</template>
                                <HbTextField
                                    v-model="assignedToId"
                                    placeholder="Enter Assigned to ID"
                                />
                            </hb-form>

                            <hb-form label="assigned-to">
                                <HbTextField
                                    v-model="assignedTo"
                                    placeholder="Enter Assigned to"
                                />
                            </hb-form>

                            <hb-form label="created-by-id">
                                <template v-slot:tooltipBody>Optional</template>
                                <HbTextField
                                    v-model="createdById"
                                    placeholder="Enter Created by ID"
                                />
                            </hb-form>

                            <hb-form label="created-by">
                                <HbTextField
                                    v-model="createdBy"
                                    placeholder="Enter Created by"
                                />
                            </hb-form>

                            <hb-form label="created-by-time">
                                <HbTextField
                                    v-model="createdByTime"
                                    placeholder="Enter Created by Time"
                                />
                            </hb-form>

                            <hb-form label="completed-by-id">
                                <template v-slot:tooltipBody>Optional</template>
                                <HbTextField
                                    v-model="completedById"
                                    placeholder="Enter Completed by ID"
                                />
                            </hb-form>

                            <hb-form label="completed-by">
                                <HbTextField
                                    v-model="completedBy"
                                    placeholder="Enter Completed by"
                                />
                            </hb-form>

                            <hb-form label="completed-by-time">
                                <HbTextField
                                    v-model="completedByTime"
                                    placeholder="Enter Completed by Time"
                                />
                            </hb-form>

                            <hb-form label="updated-by-id">
                                <template v-slot:tooltipBody>Optional</template>
                                <HbTextField
                                    v-model="updatedById"
                                    placeholder="Enter Updated by ID"
                                />
                            </hb-form>

                            <hb-form label="updated-by">
                                <HbTextField
                                    v-model="updatedBy"
                                    placeholder="Enter Updated by"
                                />
                            </hb-form>

                            <hb-form label="updated-by-time">
                                <HbTextField
                                    v-model="updatedByTime"
                                    placeholder="Enter Updated by Time"
                                />
                            </hb-form>

                            <hb-form label="attachments">
                                <HbTextField
                                    v-model="attachments"
                                    placeholder="Enter Attachments"
                                />
                            </hb-form>

                        </hb-card>

                    </v-col>

                    <v-col cols="4" no-gutters>

                        <hb-card class="mt-2" no-title width="552">

                            <hb-form v-if="lastEmittedEvent" label="Last Emitted Event">
                                {{ lastEmittedEvent }}
                            </hb-form>

                            <hb-form label="button-one">
                                <HbTextField
                                    v-model="buttonOne"
                                    placeholder="Enter Button One"
                                />
                            </hb-form>

                            <hb-form v-if="buttonOne" label="button-one-loading">
                                <HbSwitch
                                    v-model="buttonOneLoading"
                                    :label="buttonOneLoading ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="buttonOne" label="button-one-disabled">
                                <HbSwitch
                                    v-model="buttonOneDisabled"
                                    :label="buttonOneDisabled ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="button-two">
                                <HbTextField
                                    v-model="buttonTwo"
                                    placeholder="Enter Button Two"
                                />
                            </hb-form>

                            <hb-form v-if="buttonTwo" label="button-two-loading">
                                <HbSwitch
                                    v-model="buttonTwoLoading"
                                    :label="buttonTwoLoading ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="buttonTwo" label="button-two-disabled">
                                <HbSwitch
                                    v-model="buttonTwoDisabled"
                                    :label="buttonTwoDisabled ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form label="button-three">
                                <HbTextField
                                    v-model="buttonThree"
                                    placeholder="Enter Button Three"
                                />
                            </hb-form>

                            <hb-form v-if="buttonThree" label="button-three-loading">
                                <HbSwitch
                                    v-model="buttonThreeLoading"
                                    :label="buttonThreeLoading ? 'true' : 'false/null'"
                                />
                            </hb-form>

                            <hb-form v-if="buttonThree" label="button-three-disabled">
                                <HbSwitch
                                    v-model="buttonThreeDisabled"
                                    :label="buttonThreeDisabled ? 'true' : 'false/null'"
                                />
                            </hb-form>

                        </hb-card>

                    </v-col>

                    <v-col cols="12" no-gutters class="mb-1">
                        <hb-card color="#E0F5F5" title="Default Task Card View Code + Data">
                            <pre class="px-4" :style="codeExpanded1 ? 'height:auto' : 'height:320px;'">
                                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="codeExpanded1 = !codeExpanded1">
                                    {{ codeExpanded1 ? 'Collapse Code' : 'Expand Code' }}
                                    <hb-icon>mdi-chevron-down</hb-icon>
                                </div>
&lt;hb-task
    type="{{ type }}"
    id="{{ id }}"
    status="{{ status }}"
    :hot-task="{{ hotTask }}"
    hot-task-tooltip-header="{{ hotTaskTooltipHeader }}"
    hot-task-tooltip-body="{{ hotTaskTooltipBody }}"
    icon="{{ icon }}"
    title="{{ title }}"
    :expanded.sync="{{ expanded }}"
    time="{{ time }}"
    timer-green-value="{{ timerGreenValue }}"
    timer-red-value="{{ timerRedValue }}"
    assigned-to-id="{{ assignedToId }}"
    assigned-to="{{ assignedTo }}"
    created-by-id="{{ createdById }}"
    created-by="{{ createdBy }}"
    created-by-time="{{ createdByTime }}"
    completed-by-id="{{ completedById }}"
    completed-by="{{ completedBy }}"
    completed-by-time="{{ completedByTime }}"
    updated-by-id="{{ updatedById }}"
    updated-by="{{ updatedBy }}"
    updated-by-time="{{ updatedByTime }}"
    attachments="{{ attachments }}"
    button-one="{{ buttonOne }}"
    :button-one-loading="{{ buttonOneLoading }}"
    :button-one-disabled="{{ buttonOneDisabled }}"
    button-two="{{ buttonTwo }}"
    :button-two-loading="{{ buttonTwoLoading }}"
    :button-two-disabled="{{ buttonTwoDisabled }}"
    button-three="{{ buttonThree }}"
    :button-three-loading="{{ buttonThreeLoading }}"
    :button-three-disabled="{{ buttonThreeDisabled }}"
    space-id="{{ spaceId }}"
    space="{{ space }}"
    space-type="{{ spaceType }}"
    contact-id="{{ contactId }}"
    :contact-is-account="{{ contactIsAccount }}"
    :contact-is-business="{{ contactIsBusiness }}"
    contact-name="{{ contactName }}"
    contact-status="{{ contactStatus }}"
    property-id="{{ propertyId }}"
    property="{{ property }}"
    footer-text="{{ footerText }}"
    @click="handleClick"
    @button-one-click="handleButtonOneClick"
    @button-two-click="handleButtonTwoClick"
    @button-three-click="handleButtonThreeClick"
>
    {{ message }}
&lt;/hb-task>
                            </pre>
                        </hb-card>
                    
                    </v-col>

                    <v-col cols="12" no-gutters class="mt-5 mb-1">
                        <hb-card color="#E0F5F5" title="Modal View Code + Data">
                            <pre class="px-4" :style="codeExpanded2 ? 'height:auto' : 'height:320px;'">
                                <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="codeExpanded2 = !codeExpanded2">
                                    {{ codeExpanded2 ? 'Collapse Code' : 'Expand Code' }}
                                    <hb-icon>mdi-chevron-down</hb-icon>
                                </div>
&lt;hb-task
    modal-view
    type="{{ type }}"
    id="{{ id }}"
    status="{{ status }}"
    :hot-task="{{ hotTask }}"
    hot-task-tooltip-header="{{ hotTaskTooltipHeader }}"
    hot-task-tooltip-body="{{ hotTaskTooltipBody }}"
    icon="{{ icon }}"
    title="{{ title }}"
    :expanded.sync="{{ expanded }}"
    time="{{ time }}"
    timer-green-value="{{ timerGreenValue }}"
    timer-red-value="{{ timerRedValue }}"
    assigned-to-id="{{ assignedToId }}"
    assigned-to="{{ assignedTo }}"
    created-by-id="{{ createdById }}"
    created-by="{{ createdBy }}"
    created-by-time="{{ createdByTime }}"
    completed-by-id="{{ completedById }}"
    completed-by="{{ completedBy }}"
    completed-by-time="{{ completedByTime }}"
    updated-by-id="{{ updatedById }}"
    updated-by="{{ updatedBy }}"
    updated-by-time="{{ updatedByTime }}"
    attachments="{{ attachments }}"
    button-one="{{ buttonOne }}"
    :button-one-loading="{{ buttonOneLoading }}"
    :button-one-disabled="{{ buttonOneDisabled }}"
    button-two="{{ buttonTwo }}"
    :button-two-loading="{{ buttonTwoLoading }}"
    :button-two-disabled="{{ buttonTwoDisabled }}"
    button-three="{{ buttonThree }}"
    :button-three-loading="{{ buttonThreeLoading }}"
    :button-three-disabled="{{ buttonThreeDisabled }}"
    space-id="{{ spaceId }}"
    space="{{ space }}"
    space-type="{{ spaceType }}"
    contact-id="{{ contactId }}"
    :contact-is-account="{{ contactIsAccount }}"
    :contact-is-business="{{ contactIsBusiness }}"
    contact-name="{{ contactName }}"
    contact-status="{{ contactStatus }}"
    property-id="{{ propertyId }}"
    property="{{ property }}"
    footer-text="{{ footerText }}"
    @button-one-click="handleButtonOneClick"
    @button-two-click="handleButtonTwoClick"
    @button-three-click="handleButtonThreeClick"
>
    &lt;div class="mb-4">{{ message }}&lt;/div>

    &lt;hb-media-viewer
        v-model="mediaViewer"
        grid
        grid-padding-off
    >
        &lt;template v-slot:slides>
            &lt;slide
                v-for="(slide, i) in slides"
                :key="'slide_' + i"
                :id="slide.id"
                :video="slide.video"
                :video_src="slide.video_src"
                :src="slide.src"
                :thumbnail="slide.thumbnail"
            />
        &lt;/template>
    &lt;/hb-media-viewer>

    &lt;div class="hb-font-body-medium hb-text-night mt-n3">Attachments&lt;/div>

    &lt;div class="mt-3">
        &lt;hb-chip prepend-icon="mdi-paperclip" pointer @click="doWhatever">documents1.pdf&lt;/hb-chip>
        &lt;hb-chip prepend-icon="mdi-paperclip" pointer @click="doWhatever">documents2.pdf&lt;/hb-chip>
    &lt;/div>
&lt;/hb-task>

slides: [
    {
        "id": "0123456",
        "video": false,
        "src": "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
        "thumbnail": ""
    },
    {
        "id": "1234567",
        "video": true,
        "video_src": "https://videos.pexels.com/video-files/4772983/4772983-sd_360_640_24fps.mp4",
        "src": "https://images.pexels.com/videos/4772983/pexels-photo-4772983.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        "thumbnail": ""
    },
    {
        "id": "2345678",
        "video": false,
        "src": "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
        "thumbnail": ""
    },
    {
        "id": "3456789",
        "video": false,
        "src": "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
        "thumbnail": ""
    },
    {
        "id": "4567890",
        "video": true,
        "video_src": "https://videos.pexels.com/video-files/4874384/4874384-sd_640_360_30fps.mp4",
        "src": "https://images.pexels.com/videos/4874384/4k-8-alberi-animale-4874384.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        "thumbnail": ""
    },
    {
        "id": "5678901",
        "video": false,
        "src": "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
        "thumbnail": ""
    }
],
                            </pre>
                        </hb-card>
                    
                    </v-col>

                </v-row>
            </div>

        </hb-card>

        <hb-card title="HbTask Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbTask Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbTask Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="successError"
            type="success"
            :list="successErrorList"
            :description="successErrorDescription"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">
    import tokens from '../../aviary/tokens.js';
    import moment from 'moment';

    export default {
        name: "DesignSystemTasks",
        data: function() {
            return {
                expanded: false,
                id: '1232432',
                type: 'contact',
                contactId: '453243543',
                contactIsAccount: false,
                contactIsBusiness: false,
                contactName: 'Neville Longbottom',
                contactEmail: 'nevillelongbottom@hogwarts.edu',
                contactPhone: '+1 (800) 222-2222',
                contactStatus: 'current',
                spaceId: '2345435436',
                space: '101',
                spaceType: 'storage',
                hotTask: true,
                hotTaskTooltipHeader: '',
                hotTaskTooltipBody: 'Hot Task',
                icon: '',
                title: '',
                time: '12:00am',
                timerGreenValue: '1 hr 15 min',
                timerRedValue: '1 hr 15 min',
                propertyId: '342643264',
                property: 'SO124 - Pomona - 435 Airport Way',
                message: 'Lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler lorem ipsum doler.',
                status: 'in progress',
                footerText: 'General',
                assignedToId: '23523562464',
                assignedTo: 'Bellatrix LeStrange',
                createdById: '2346234656',
                createdBy: 'Voldemort',
                createdByTime: 'Jun 20, 2024 @ 12:30pm',
                completedById: '245752467',
                completedBy: 'Bellatrix LeStrange',
                completedByTime: 'Jun 23, 2024 @ 12:30pm',
                updatedById: '7234324768463',
                updatedBy: 'Bellatrix LeStrange',
                updatedByTime: 'Jun 29, 2024 @ 12:30pm',
                attachments: '5 images, 3 comments, 1 attachment',
                buttonOne: 'Update',
                buttonOneLoading: false,
                buttonOneDisabled: false,
                buttonTwo: 'Reopen Task',
                buttonTwoLoading: false,
                buttonTwoDisabled: false,
                buttonThree: 'Dismiss Task',
                buttonThreeLoading: false,
                buttonThreeDisabled: false,

                codeExpanded1: false,
                codeExpanded2: false,
                setTime: 'today',
                statusItems: [ 'due date', 'overdue', 'in progress', 'complete', 'dismissed', 'timer green', 'timer red' ],
                spaceTypeItems: tokens.icons.spaces,
                modalView: false,
                mediaViewer: false,
                slides: [
                    {
                        "id": "0123456",
                        "video": false,
                        "src": "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
                        "thumbnail": ""
                    },
                    {
                        "id": "1234567",
                        "video": true,
                        "video_src": "https://videos.pexels.com/video-files/4772983/4772983-sd_360_640_24fps.mp4",
                        "src": "https://images.pexels.com/videos/4772983/pexels-photo-4772983.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
                        "thumbnail": ""
                    },
                    {
                        "id": "2345678",
                        "video": false,
                        "src": "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
                        "thumbnail": ""
                    },
                    {
                        "id": "3456789",
                        "video": false,
                        "src": "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
                        "thumbnail": ""
                    },
                    {
                        "id": "4567890",
                        "video": true,
                        "video_src": "https://videos.pexels.com/video-files/4874384/4874384-sd_640_360_30fps.mp4",
                        "src": "https://images.pexels.com/videos/4874384/4k-8-alberi-animale-4874384.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
                        "thumbnail": ""
                    },
                    {
                        "id": "5678901",
                        "video": false,
                        "src": "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
                        "thumbnail": ""
                    }
                ],

                cautionNotification: false,
                cautionNotificationMessage: '',
                successError: false,
                successErrorDescription: '',
                successErrorList: [],
                lastEmittedEvent: '',
                typeItems: [ 'contact', 'space', 'undefined' ],

                propHeaders: [
                    { text: 'Name', value: 'name', width: '140' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Has Payload', value: 'payload' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'type', type: 'string', default: 'undefined', description: 'Set this prop appropriately to display the proper footer information for the intended task type. The available options are "contact", "space", and "undefined" (or no value set).' },
                    { name: 'id', type: 'string', default: 'undefined', description: 'Optional. Pass the id of the task into this prop. This will output to the payload on all task click events to make it easier for subsequent actions.' },
                    { name: 'status', type: 'string', default: 'undefined', description: 'Pass the status of the task here which affects what is displayed in the top right corner. It can also affect the disabled state as well as hot task availability. The available options are "due date", "overdue", "in progress", "complete", "dismissed", "timer green", and "timer red". Please use the protoype to understand how all statuses affect the content.' },
                    { name: 'hot-task', type: 'boolean', default: 'false', description: 'If set to true, applies the hot task top left corner red overlay. Will not show up if "status" is set to "complete" or "dismissed".' },                    
                    { name: 'hot-task-tooltip-header', type: 'string', default: 'undefined', description: 'Sets the optional hot task tooltip header text.' },
                    { name: 'hot-task-tooltip-body', type: 'string', default: 'Hot Task', description: 'Sets the hot task tooltip body text.' },
                    { name: 'icon', type: 'string', default: 'undefined', description: 'Sets the task icon (use "mdi-xxxx" format).' },
                    { name: 'icon-color', type: 'string', default: 'undefined', description: 'Override the task icon color. You should not ever need to do this.' },
                    { name: 'icon-position-adjustment', type: 'string', default: '-1.5px', description: 'Optional ability to manually adjust the vertical position of the icon. Useful when certain icons are not aligning correctly. You usually should not need to use or adjust this prop.' },
                    { name: 'disable-icon-position-adjustment', type: 'boolean', default: 'false', description: 'Optional ability to disable the "icon-position-adjustment" prop if set to true. You should never need to use or adjust this prop.' },
                    { name: 'title', type: 'string', default: 'undefined', description: 'Sets the task title text.' },
                    { name: 'title-color', type: 'string', default: 'undefined', description: 'Override the task title text color. You should not ever need to do this.' },
                    { name: 'expanded', type: 'boolean', default: 'false', description: 'Important: This prop must be set with the ".sync" modifier to work correctly such as ":expanded.sync=etc...". This makes it so clicking on the expand and collapse icons will update the prop correctly. Please reference the code in the examples for exact usage. This prop expands and collapses the main content of the task and shows the task details.' },
                    { name: 'time', type: 'string', default: 'undefined', description: 'Pass the time of the task into this prop. Please see the examples on proper styling format of the time. The only options should be "h:mma" or "MMM D, YYYY [ @ ] h:mma" for anything else. You can use "moment.js" to format the time. Time only displays when "status" is "due date" or "overdue" or "undefined" (or no status set).' },
                    { name: 'timer-green-value', type: 'string', default: 'undefined', description: 'Pass the timer green value here when "status" is set to "timer green". When "timer-green-value" has reach a 0 state, change "status" to "timer red" and use "timer-red-value" to change the color from green to red and begin your new timer.' },
                    { name: 'timer-red-value', type: 'string', default: 'undefined', description: 'Pass the timer red value here when "status" is set to "timer red". When "timer-green-value" has reach a 0 state, change "status" to "timer red" and use "timer-red-value" to change the color from green to red and begin your new timer.' },
                    { name: 'assigned-to-id', type: 'string', default: 'undefined', description: 'Optional. Pass the assigned to id of the task into this prop. This will output to the payload on all task click events to make it easier for subsequent actions.' },
                    { name: 'assigned-to', type: 'string', default: 'undefined', description: 'Set the assigned to name here for display in the expanded details dropdown.' },
                    { name: 'created-by-id', type: 'string', default: 'undefined', description: 'Optional. Pass the created by id of the task into this prop. This will output to the payload on all task click events to make it easier for subsequent actions.' },
                    { name: 'created-by', type: 'string', default: 'undefined', description: 'Set the created by name here for display in the expanded details dropdown.' },
                    { name: 'created-by-time', type: 'string', default: 'undefined', description: 'Set the created by time here for display in the expanded details dropdown.' },
                    { name: 'completed-by-id', type: 'string', default: 'undefined', description: 'Optional. Pass the completed by id of the task into this prop. This will output to the payload on all task click events to make it easier for subsequent actions.' },
                    { name: 'completed-by', type: 'string', default: 'undefined', description: 'Set the completed by name here for display in the expanded details dropdown.' },
                    { name: 'completed-by-time', type: 'string', default: 'undefined', description: 'Set the completed by time here for display in the expanded details dropdown.' },
                    { name: 'updated-by-id', type: 'string', default: 'undefined', description: 'Optional. Pass the updated by id of the task into this prop. This will output to the payload on all task click events to make it easier for subsequent actions.' },
                    { name: 'updated-by', type: 'string', default: 'undefined', description: 'Set the updated by name here for display in the expanded details dropdown.' },
                    { name: 'updated-by-time', type: 'string', default: 'undefined', description: 'Set the updated by time here for display in the expanded details dropdown.' },
                    { name: 'attachments', type: 'string', default: 'undefined', description: 'Set the attachments text for display in the expanded details dropdown.' },
                    { name: 'button-one', type: 'string', default: 'undefined', description: 'Sets the optional button one text and turns this button on for use in the expanded details dropdown.' },
                    { name: 'button-one-loading', type: 'boolean', default: 'false', description: 'If set to true, puts button one in a loading and disabled state.' },
                    { name: 'button-one-disabled', type: 'boolean', default: 'false', description: 'If set to true, puts button one in a disabled state.' },
                    { name: 'button-two', type: 'string', default: 'undefined', description: 'Sets the optional button two text and turns this button on for use in the expanded details dropdown.' },
                    { name: 'button-two-loading', type: 'boolean', default: 'false', description: 'If set to true, puts button two in a loading and disabled state.' },
                    { name: 'button-two-disabled', type: 'boolean', default: 'false', description: 'If set to true, puts button two in a disabled state.' },
                    { name: 'button-three', type: 'string', default: 'undefined', description: 'Sets the optional button three text and turns this button on for use in the expanded details dropdown.' },
                    { name: 'button-three-loading', type: 'boolean', default: 'false', description: 'If set to true, puts button three in a loading and disabled state.' },
                    { name: 'button-three-disabled', type: 'boolean', default: 'false', description: 'If set to true, puts button three in a disabled state.' },
                    { name: 'space-id', type: 'string', default: 'undefined', description: 'Optional. Pass the space id of the space associated with the task into this prop. This will output to the payload on all task click events to make it easier for subsequent actions.' },
                    { name: 'space', type: 'string', default: 'undefined', description: 'Pass the space number/name associated with the task into this prop.' },
                    { name: 'space-type', type: 'string', default: 'undefined', description: 'Pass the space type associated with the space into this prop. Refer to the design system "tokens.js" file for all possible space types that can be passed into this prop.' },
                    { name: 'contact-id', type: 'string', default: 'undefined', description: 'Optional. Pass the contact id of the contact associated with the task into this prop. This will output to the payload on all task click events to make it easier for subsequent actions.' },
                    { name: 'contact-is-account', type: 'boolean', default: 'false', description: 'If set to true, this will display the correct national account icon in the contact information area.' },
                    { name: 'contact-is-business', type: 'boolean', default: 'false', description: 'If set to true, this will display the correct business icon in the contact information area.' },
                    { name: 'contact-name', type: 'string', default: 'undefined', description: 'Pass the contact or business name of the contact or business associated with the task into this prop.' },
                    { name: 'contact-status', type: 'string', default: 'undefined', description: 'Pass the status of the contact or business associated with the task into this prop.' },
                    { name: 'property-id', type: 'string', default: 'undefined', description: 'Optional. Pass the property id of the property associated with the task into this prop. This will output to the payload on all task click events to make it easier for subsequent actions.' },
                    { name: 'property', type: 'string', default: 'undefined', description: 'Pass the property display text here. Follow the same app-wise property display formatting. This text will show up in the footer in "space" "type" and will show up in the expanded details sections for all other kinds of "type".' },
                    { name: 'modal-view', type: 'boolean', default: 'false', description: 'If set to true, this will enable the task to look good and behave correctly in modals. Only use this for tasks in a modal. It will not work for any other usage.' },
                    { name: 'modal-view-sticky-footer', type: 'boolean', default: 'true', description: 'If set to false, this will turn off the stickiness of the footer in modal view. You should never set this to false as the default footer behavior should be sticky, but the option is there if you need for some reason.' },
                ],
                slotItems: [
                    { name: 'default', description: 'This slot is where you would place the main message/description for the task.' },
                    { name: 'timerGreen', description: 'This slot is a secondary option to set the "timer-green" status value. Use this if the "timer-green-value" prop is not sufficient for your usage.' },
                    { name: 'timerRed', description: 'This slot is a secondary option to set the "timer-red" status value. Use this if the "timer-red-value" prop is not sufficient for your usage.' },
                ],
                eventItems: [
                    { name: '@click', payload: 'Yes', description: 'Event emitted whenever anywhere in the task is clicked that is not the dropdown icon or the buttons in the expanded details.' },
                    { name: '@button-one-click', payload: 'Yes', description: 'Event emitted when the optional button one is clicked.' },
                    { name: '@button-two-click', payload: 'Yes', description: 'Event emitted when the optional button two is clicked.' },
                    { name: '@button-three-click', payload: 'Yes', description: 'Event emitted when the optional button three is clicked.' }
                ],
            };
        },
        created(){
            this.time = moment().format('h:mma');
        },
        methods: {
            handleClick(payload){
                this.successErrorList = [
                    'Event: "@click"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.expanded = false;
                this.modalView = true;
                this.lastEmittedEvent = '@click';
            },
            handleButtonOneClick(payload){
                this.successErrorList = [
                    'Event: "@button-one-click"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@button-one-click';
                this.buttonOneLoading = true;
                setTimeout(() => {
                    this.buttonOneLoading = false;
                }, 3000);
            },
            handleButtonTwoClick(payload){
                this.successErrorList = [
                    'Event: "@button-two-click"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@button-two-click';
                this.buttonTwoLoading = true;
                setTimeout(() => {
                    this.buttonTwoLoading = false;
                }, 3000);
            },
            handleButtonThreeClick(payload){
                this.successErrorList = [
                    'Event: "@button-three-click"',
                    'Payload: ' + JSON.stringify(payload, null, 2),
                ]
                this.successError = true;
                this.lastEmittedEvent = '@button-three-click';
                this.buttonThreeLoading = true;
                setTimeout(() => {
                    this.buttonThreeLoading = false;
                }, 3000);
            }
        },
        watch: {
            type(){
                this.icon = '';
                this.title = '';
            },
            setTime(){
                if(this.preview){
                    if(this.setTime == 'today'){
                        this.time = moment().format('h:mma');
                    } else if(this.setTime == 'yesterday'){
                        this.time = moment().subtract(1, 'days').format('MMM D, YYYY');
                    } else if(this.setTime == 'two days ago'){
                        this.time = moment().subtract(2, 'days').format('MMM D, YYYY');
                    }
                } else {
                    if(this.setTime == 'today'){
                        this.time = moment().format('h:mma');
                    } else if(this.setTime == 'yesterday'){
                        this.time = moment().subtract(1, 'days').format('MMM D, YYYY [ @ ] h:mma');
                    } else if(this.setTime == 'two days ago'){
                        this.time = moment().subtract(2, 'days').format('MMM D, YYYY [ @ ] h:mma');
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
